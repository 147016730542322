import swal from 'sweetalert';
import $ from 'jquery';
import I18n from 'i18n-js';
import select2 from 'select2';
import 'javascripts/i18n/translations';
import {toastr} from './notifications';
import Chart from 'chart.js/auto';


I18n.locale = window.I18n.locale;

document.addEventListener("turbolinks:before-cache", function () {
    $('[data-toggle="m-tooltip"]').tooltip('hide');
    $('.selectpicker').selectpicker('destroy');
});


$(document).on('click', 'a#open_btn_modal[data-toggle="m-tooltip"]', function(e) {
    e.preventDefault();
    var target = $(this).data('target');
    var userEventId = $(this).data('usereventid');
    var contType = $(this).data('conttype');
    var revId = $(this).data('reviewerid');
    var rev2Id = $(this).data('reviewer2id');

    $(target).modal('show');
    $('#user_event_id').val(userEventId);
    $('#cont_type').val(contType);

    getReviewers(revId, rev2Id, userEventId);
});

$(document).on('click', 'a#open_invalidate_modal[data-toggle="m-tooltip"]', function(e) {
    e.preventDefault();
    var target = $(this).data('target');
    var userEventId = $(this).data('usereventid');
    var contType = $(this).data('conttype');
    var folio = $(this).data('folio');
    var comment = $(this).data('invcomment');
    var modalTitle = $('#modalTitle');

    $(target).modal('show');
    $('#contribution_id').val(userEventId);
    $('#invalidate_comment').val(comment);

    modalTitle.text(modalTitle.text() + ": " + folio + " - " + contType.toUpperCase());
});


$(document).on('click', 'a#enroll-activity', function(e) {
    e.preventDefault();
    var activityId      = $(this).data('id');
    var activityTitle   = $(this).data('title');
    var activityDesc    = $(this).data('desc');
    var activityDates   = $(this).data('dates');
    var userId          = $(this).data('userid');

    swal({
        title: '¿DESEAS INSCRIBIRTE AL EVENTO '+activityTitle + ' ?',
        text: activityDesc + "\n"+activityDates,
        html: true,
        buttons: {
            cancel: I18n.t('messages.cancel'),
            confirm: {
                text: "CONFIRMAR",
                closeModal: true
            },
        }
    }).then(function (isConfirm) {
        if(isConfirm){
            $.ajax({
                method: 'POST',
                url: '/enroll_into_activity',
                contentType: "application/json",
                //dataType: 'json',
                data: JSON.stringify({user_id: userId, activity_id: activityId}),
                success: function (result) {
                    /*swal({
                        title: '¡INSCRITO!',
                        icon: 'success',
                        timer: 3000,
                        buttons: false,
                        customClass: {
                            popup: 'pb-10'
                        }
                    });*/
                },
                error: function (error) {
                    console.log(error);
                }
            });
            }
        });
});


$(document).on('turbolinks:load', function () {


    //$('.select2').select2();

    $('.select2').select2({
        language: {
            noResults: function (params) {
                return "Sin resultados";
            }
        },
        theme: "classic",
        formatNoMatches: "Sin resultados",
        placeholder: "Seleccione una opcion",
        allowClear: true
    });

    $('#reviewers_select').select2({
        theme: "classic",
        formatNoMatches: "Sin resultados",
        language: {
            noResults: function (params) {
                return "Sin resultados";
            }
        },
        placeholder: "Seleccione una opción",
        dropdownParent: $('#modal_assign_user')
    });

    $('#reviewers_select_2').select2({
        theme: "classic",
        formatNoMatches: "Sin resultados",
        language: {
            noResults: function (params) {
                return "Sin resultados";
            }
        },
        placeholder: "Seleccione una opción",
        dropdownParent: $('#modal_assign_user')
    });

    /* Functions to dynamically open pdf located on Public*/
    var pdf_modal = $('#pdf-modal');
    pdf_modal.on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var pdfUrl = button.attr('href');
        var pdfTitle = button.data('title');
        var modal = $(this);
        modal.find('.modal-title').text(pdfTitle);
        modal.find('#pdf-iframe').attr('src', pdfUrl);
    });

    pdf_modal.on('hidden.bs.modal', function () {
        $('#pdf-iframe').attr('src', '');
    });
    /******************************************************/

    /***MODAL PROMOCIONAL XALAPA**/
    // 2024 DGO
    // 2025 CUU MODAL INICIAL
    $("#pdf-promotional").modal('show');

    /***MODAL POR ACTIVIDAD ******/
    var activity_modal = $('#activity-modal');
    var activity_modal_2 = $('#activity-modal-2');
    activity_modal.on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var pdfUrl = button.attr('href');
        var pdfTitle = button.data('title');
        var register = button.data('register');
        var modal = $(this);
        modal.find('.modal-title').text(pdfTitle);
        if(register){
            modal.find('#pdf-iframe').attr('src', pdfUrl);
            $('.activity-file').show();
            $('.activity-img').hide();
        }else{
            modal.find('#pdf-img').attr('src', pdfUrl);
            $('.activity-img').show();
            $('.activity-file').hide();
        }
        modal.find('.modal-title').text(pdfTitle);
    });

    activity_modal.on('hidden.bs.modal', function () {
        $('#pdf-iframe').attr('src', '');
        $('#pdf-img').attr('src', '');
    });

    activity_modal_2.on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget); // Button that triggered the modal
        var pdfUrl = button.attr('href');
        var pdfTitle = button.data('title');
        var register = button.data('register');
        var modal = $(this);
        modal.find('.modal-title').text(pdfTitle);
        if(register){
            modal.find('#pdf-iframe').attr('src', pdfUrl);
            $('.activity-file').show();
            $('.activity-img').hide();
        }else{
            modal.find('#pdf-img').attr('src', pdfUrl);
            $('.activity-img').show();
            $('.activity-file').hide();
        }
        modal.find('.modal-title').text(pdfTitle);
    });

    activity_modal_2.on('hidden.bs.modal', function () {
        $('#pdf-iframe').attr('src', '');
        $('#pdf-img').attr('src', '');
    });
    /*$('a.card-activity').click(function (e) {
        //e.preventDefault();
        var button = $(e.relatedTarget); // Button that triggered the modal
        var pdfUrl = button.attr('href');
        var register = button.data('register');
        var modal = $(this);
        if(register){
            modal.find('#pdf-iframe').attr('src', pdfUrl);
            $('.activity-file').show();
            $('.activity-img').hide();
        }else{
            var imageSrc = $(this).data('image');
        }
        $('#modalImage').attr('src', imageSrc);
        $('#activity-modal').modal('show');
    });*/
    /*******************GOOGLE ANALYTICS******************************/
    gtag('config', 'G-2W3X6CC8CL', {'page_location': event.data.url});


    global.Chart = Chart;

    if(document.getElementById('myChart')){
        var ctx = document.getElementById('myChart').getContext('2d');

        var myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: JSON.parse(ctx.canvas.dataset.labels),
                datasets: [{
                    label: '',
                    data: JSON.parse(ctx.canvas.dataset.data),
                }]
            },options: {
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });

        var ctx_donut = document.getElementById('myChartDonut').getContext('2d');
        var myChartDonut = new Chart(ctx_donut, {
            type: 'doughnut',
            data: {
                labels: JSON.parse(ctx_donut.canvas.dataset.labels),
                datasets: [{
                    label: '',
                    data: JSON.parse(ctx_donut.canvas.dataset.data),
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
    }

    $(".navbar-toggler").click(function (e) {
        e.preventDefault();

        $("#navbarNav").toggleClass("hidden");
    });

    $('[data-provider="summernote"]').each(function () {
        $(this).summernote({
            height: 300,
            toolbar: [
                // [groupName, [list of button]]
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['strikethrough']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['insert', ['link', 'picture', 'video']]
            ]
        });
    });


    if($('#select_unit_id').val() !== undefined){
        getCentByUnit();

    }

    $('#select_unit_id').on('change', function () {
        getCentByUnit();
    });

    /*$('#select_faculty_id').on('change', function () {
        $.ajax({
            type: 'GET',
            url: '/get_program_by_faculty/' + $("#select_faculty_id").val(),
            contentType: "application/json",
            dataType: 'json',
            data: {clave_anexo: $("#clave_anexo").val()},
            success: function (result) {
                if (result) {
                    var array = [];
                    array.push('<option value="">Selecciona una carrera</option>');

                    $.each(result, function (i, val) {
                        array.push('<option value="' + val.IdProgramaEducativo + '">' + val.Descripcion + '</option>');
                    });

                    $('#select_program_id').empty().append(array.join(''));
                }
            },
            error: function (error) {
                $("#select_program_id").empty()
                $("#select_program_id").append($("<option></option>").attr("value", '').text('Selecciona una carrera'))
            }
        });
    });*/

    $('a.m-portlet__toggler').on('click', function() {
        var icon = $(this).find('svg') || $(this).find('i');
        if (icon.hasClass('fa-chevron-up')) {
            icon.removeClass('fa-chevron-up');
            icon.addClass('fa-chevron-down');
        } else {
            icon.removeClass('fa-chevron-down');
            icon.addClass('fa-chevron-up');
        }
    });

    /**Manage duplicated selection on reviewers**/
    $('#reviewers_select').change(function() {
        var revId = $(this).val();
        var rev2Id = $('#reviewers_select_2').val();
        getReviewers(revId, rev2Id);
    });

    $('#reviewers_select_2').change(function() {
        var rev2Id = $(this).val();
        var revId = $('#reviewers_select').val();
        getReviewers(revId, rev2Id);
    });
    /* ****************************************** */


    /**FUNCTION TO AUTOFOCUS DETERMINATED DIVS BY CLASS**/
    var targetDiv = $('.autofocus-div');
    if (targetDiv.length > 0) {
        $('html, body').animate({
            scrollTop: (targetDiv.offset().top - 80)
        }, 500); // Adjust the animation duration as needed
    }
    /****************************************************/



    /****VALIDACION DE PAGOS DE USUARIOS*******/
    $('.validate-payment').click((e)=>{
        let authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content
        let $this = $(e.target);
        let username = $this.prop('nodeName') == 'I' ? $this.parent().data('username') : $this.data('username');
        let institution = $this.prop('nodeName') == 'I' ? $this.parent().data('institution') : $this.data('institution');
        let payment_forgiven = $this.prop('nodeName') == 'I' ? $this.parent().data('paymentforgiven') : $this.data('paymentforgiven');

        let message = '';

        if(payment_forgiven === 'true' || payment_forgiven){
            message = `Se condonará la cuota de recuperación del usuario: ${username}`
        }else{
            message = `Se validará la cuota de recuperación del usuario: ${username}`
        }


        swal({
            icon: 'warning',
            title: message  ,
            text: "¿Está seguro de continuar?",
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Confirmar",
                    closeModal: true
                },
            }
        }).then(function (value) {
            if (value) {
                let url = $this.prop('nodeName') == 'I' ? $this.parent().data('url') : $this.data('url');
                $.ajax({
                    url: url,
                    data: {
                        authenticity_token: authenticityToken,
                        payment_forgiven: payment_forgiven
                    },
                    type: 'put',
                    dataType: 'json',
                    success: function (data) {
                        window.location.href = '/usuarios_registrados'
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        swal(xhr.responseJSON.message,'','error')
                    }
                });
            }
        });

    });

    $('.invalidate-payment').click((e)=>{
        let authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content
        let $this = $(e.target);
        let username = $this.prop('nodeName') == 'I' ? $this.parent().data('username') : $this.data('username');

        swal({
            icon: 'warning',
            title: `Se invalidará la cuota de recuperación del usuario: ${username}`  ,
            text: "¿Está seguro de continuar?",
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Confirmar",
                    closeModal: true
                },
            }
        }).then(function (value) {
            if (value) {
                let url = $this.prop('nodeName') == 'I' ? $this.parent().data('url') : $this.data('url');
                $.ajax({
                    url: url,
                    data: {
                        authenticity_token: authenticityToken
                    },
                    type: 'put',
                    dataType: 'json',
                    success: function (data) {
                        window.location.href = '/usuarios_registrados'
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        swal(xhr.responseJSON.message,'','error')
                    }
                });
            }
        });

    });

    $('.forward-2-billing').click((e)=>{
        let authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content
        let $this = $(e.target);
        let username = $this.prop('nodeName') == 'I' ? $this.parent().data('username') : $this.data('username');

        swal({
            icon: 'warning',
            title: `Se reenviará la cuota de recuperación a facturación de: ${username}`  ,
            text: "¿Está seguro de continuar?",
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Confirmar",
                    closeModal: true
                },
            }
        }).then(function (value) {
            if (value) {
                let url = $this.prop('nodeName') == 'I' ? $this.parent().data('url') : $this.data('url');
                $.ajax({
                    url: url,
                    data: {
                        authenticity_token: authenticityToken
                    },
                    type: 'put',
                    dataType: 'json',
                    success: function (data) {
                        swal({
                            title: I18n.t('messages.done'),
                            text: I18n.t('messages.successful_forwarding'),
                            icon: 'success',
                            button: I18n.t('messages.accept')
                        }).then(function () {
                            window.location.href = '/usuarios_registrados'
                        });
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        swal(xhr.responseJSON.message,'','error')
                    }
                });
            }
        });

    });

    /*******START HEADER AUTOSCROLL*****/
   /* var scrollSpeed = 50; // Adjust the scroll speed as needed
    var isHovered = false;

    function startScroll() {
        var div = $('.slider-container-activity');
        var scrollLeft = div.scrollLeft();
        var scrollWidth = div[0].scrollWidth;
        var clientWidth = div[0].clientWidth;

        div.animate({
            scrollLeft: scrollLeft + 1
        }, 20, 'linear', function() {
            if (scrollLeft + clientWidth >= scrollWidth) {
                div.scrollLeft(0);
            }
            if (!isHovered) {
                setTimeout(startScroll, scrollSpeed);
            }
        });
    }

    $('.slider-container-activity').hover(
        function() {
            isHovered = true;
        },
        function() {
            isHovered = false;
            startScroll();
        }
    );

    startScroll();*/
    /******* HEADER AUTOSCROLL*****/


    /******* NOTIFICATE REVIEW *****/

    $('.r_noti').click((e)=>{
        let authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        let $this = $(e.target);


        swal({
            icon: 'warning',
            title: `Se notificará revisión a contribución.`  ,
            text: "¿Está seguro de continuar?",
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Confirmar",
                    closeModal: true
                },
            }
        }).then(function (value) {
            if (value) {
                let url = $this.prop('nodeName') == 'I' ? $this.parent().data('url') : $this.data('url');
                //let review_1 = $this.prop('nodeName') == 'I' ? $this.parent().data('review1') : $this.data('review1');
                //let review_2 = $this.prop('nodeName') == 'I' ? $this.parent().data('review2') : $this.data('review2');
                //let cont_type = $this.prop('nodeName') == 'I' ? $this.parent().data('contType') : $this.data('contType');

                $.ajax({
                    url: url,
                    data: {
                        authenticity_token: authenticityToken
                    },
                    type: 'put',
                    dataType: 'json',
                    success: function (data) {
                        swal({
                            icon: 'success',
                            title: `CORREO ENVIADO`
                        });
                        window.location.href = '/lista_contribuciones'
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        swal(xhr.responseJSON.message,'','error')
                    }
                });
            }
        });

    });

    $('.r_noti_all').click((e)=>{
        let authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        let $this = $(e.target);

        let result = $this.prop('nodeName') == 'I' ? $this.parent().data('result') : $this.data('result');

        swal({
            icon: 'warning',
            title: 'Se notificarán los resultados de contribuciones '+ result,
            text: "¿Está seguro de continuar?",
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Confirmar",
                    closeModal: true
                },
            }
        }).then(function (value) {
            if (value) {
                let url = $this.prop('nodeName') == 'I' ? $this.parent().data('url') : $this.data('url');

                $.ajax({
                    url: url,
                    data: {
                        authenticity_token: authenticityToken
                    },
                    type: 'put',
                    dataType: 'json',
                    success: function (data) {
                        swal({
                            icon: 'success',
                            title: `CORREOS ENVIADOS`
                        });
                        window.location.href = '/lista_contribuciones'
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        swal(xhr.responseJSON.message,'','error')
                    }
                });
            }
        });

    });

    $('.cancel_invalidation').click((e)=>{
        let authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        let $this = $(e.target);


        swal({
            icon: 'warning',
            title: `Se cancelará la invalidación`  ,
            text: "¿Está seguro de continuar?",
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Confirmar",
                    closeModal: true
                },
            }
        }).then(function (value) {
            if (value) {
                let url = $this.prop('nodeName') == 'I' ? $this.parent().data('url') : $this.data('url');

                $.ajax({
                    url: url,
                    data: {
                        authenticity_token: authenticityToken
                    },
                    type: 'put',
                    dataType: 'json',
                    success: function (data) {
                        swal({
                            icon: 'success',
                            title: `INVALIDACIÓN CANCELADA`
                        });
                        window.location.href = '/lista_contribuciones'
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        swal(xhr.responseJSON.message,'','error')
                    }
                });
            }
        });

    });


    $('.r_notificate_all').click((e)=>{
        let authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        let $this = $(e.target);

        swal({
            icon: 'warning',
            title: `Se notificará a todos los revisores.`  ,
            text: "¿Está seguro de continuar?",
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Confirmar",
                    closeModal: true
                },
            }
        }).then(function (value) {
            if (value) {
                let url = $this.prop('nodeName') == 'I' ? $this.parent().data('url') : $this.data('url');

                $.ajax({
                    url: url,
                    data: {
                        authenticity_token: authenticityToken
                    },
                    type: 'put',
                    dataType: 'json',
                    success: function (data) {
                        swal({
                            icon: 'success',
                            title: `NOTIFICACIONES ENVIADAS`
                        });
                        window.location.href = '/reviewers'
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        swal(xhr.responseJSON.message,'','error')
                    }
                });
            }
        });

    });


    $('.r_notify_one_reviewer').click((e)=>{
        let authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        let $this = $(e.target);

        let email = $this.prop('nodeName') == 'I' ? $this.parent().data('email') : $this.data('email');


        swal({
            icon: 'warning',
            title: `Se notificará al correo ${email}, la información de apoyo de dictaminación de contribuciones.`  ,
            text: "¿Está seguro de continuar?",
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Confirmar",
                    closeModal: true
                },
            }
        }).then(function (value) {
            if (value) {
                let url = $this.prop('nodeName') == 'I' ? $this.parent().data('url') : $this.data('url');

                $.ajax({
                    url: url,
                    data: {
                        authenticity_token: authenticityToken
                    },
                    type: 'put',
                    dataType: 'json',
                    success: function (data) {
                        swal({
                            icon: 'success',
                            title: `NOTIFICACION ENVIADA`
                        });
                        window.location.href = '/reviewers'
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        swal(xhr.responseJSON.message,'','error')
                    }
                });
            }
        });

    });


    function getCentByUnit(){
        $.ajax({
            type: 'GET',
            url: '/get_cent_by_unit',
            contentType: "application/json",
            dataType: 'json',
            data: {unit_id: $("#select_unit_id").val()},
            success: function (result) {
                if (result) {
                    var array = [];

                    array.push('<option value="">Selecciona un centro</option>');

                    $.each(result, function (i, val) {
                        if($("#hidden_centro_id").val() == val.Id_UnidadPres){
                            array.push('<option selected="selected" value="' + val.Id_UnidadPres + '">' + val.Descripcion + '</option>');
                        }else{
                            array.push('<option value="' + val.Id_UnidadPres + '">' + val.Descripcion + '</option>');
                        }
                    });

                    $('#select_centro_id').empty().append(array.join(''));
                }
            }
        });
    }

    function payment_file_size() {

        var fileInput = document.getElementById("file-required");

        try {
            if (fileInput.files[0].size > 1000000) {
                swal({
                    title: "Archivo demasiado grande",
                    text: "El archivo debe pesar menos de 1 MB",
                    icon: 'warning',
                    button: I18n.t('messages.ok')
                });

                document.getElementById("file-required").value = null;
            }
        } catch (e) {

        }
    };

    function tax_situation_file_size() {

        var fileInput = document.getElementById("file-invoice-required");


        try {
            if (fileInput.files[0].size > 1000000) {
                swal({
                    title: "Archivo demasiado grande",
                    text: "El archivo debe pesar menos de 1mb",
                    icon: 'warning',
                    button: I18n.t('messages.ok')
                });

                document.getElementById("file-invoice-required").value = null;
            }
        } catch (e) {

        }
    };

    function fileSaliente() {

        var fileInput = document.getElementById("idFileSaliente");
        swal({
            title: "Archivo demasiado grande",
            text: "El archivo debe pesar menos de 2mb",
            icon: 'warning',
            button: I18n.t('messages.ok')
        });
        try {
            if (fileInput.files[0].size > 2000000) {


                document.getElementById("idFileSaliente").value = null;
            }
        } catch (e) {

        }
    }

    function fileIne() {

        var fileInput = document.getElementById("idFileIne");

        try {
            if (fileInput.files[0].size > 2000000) {
                swal({
                    title: "Archivo demasiado grande",
                    text: "El archivo debe pesar menos de 2mb",
                    icon: 'warning',
                    button: I18n.t('messages.ok')
                });
                document.getElementById("idFileIne").value = null;

            }
        } catch (e) {

        }
    };

    function fileOficio() {

        var fileInput = document.getElementById("idFileOficio");
        try {
            if (fileInput.files[0].size > 2000000) {
                swal({
                    title: "Archivo demasiado grande",
                    text: "El archivo debe pesar menos de 2mb",
                    icon: 'warning',
                    button: I18n.t('messages.ok')
                });
                document.getElementById("idFileOficio").value = null;

            }
        } catch (e) {

        }
    };

    function fileRepresentante() {


        var fileInput = document.getElementById("idFileRepresentante");

        try {
            if (fileInput.files[0].size > 2000000) {
                swal({
                    title: "Archivo demasiado grande",
                    text: "El archivo debe pesar menos de 2mb",
                    icon: 'warning',
                    button: I18n.t('messages.ok')
                });
                document.getElementById("idFileRepresentante").value = null;

            }
        } catch (e) {

        }
    };

    function fileParticipantes() {


        var fileInput = document.getElementById("idFileParticipantes");

        try {
            if (fileInput.files[0].size > 2000000) {
                swal({
                    title: "Archivo demasiado grande",
                    text: "El archivo debe pesar menos de 2mb",
                    icon: 'warning',
                    button: I18n.t('messages.ok')
                });
                document.getElementById("idFileParticipantes").value = null;

            }
        } catch (e) {

        }
    };

    function fileTestigo() {


        var fileInput = document.getElementById("idFileTestigo");

        try {
            if (fileInput.files[0].size > 2000000) {
                swal({
                    title: "Archivo demasiado grande",
                    text: "El archivo debe pesar menos de 2mb",
                    icon: 'warning',
                    button: I18n.t('messages.ok')
                });
                document.getElementById("idFileTestigo").value = null;

            }
        } catch (e) {

        }
    };

    function fileEntrante() {


        var fileInput = document.getElementById("idFileEntrante");

        try {
            if (fileInput.files[0].size > 2000000) {
                swal({
                    title: "Archivo demasiado grande",
                    text: "El archivo debe pesar menos de 2mb",
                    icon: 'warning',
                    button: I18n.t('messages.ok')
                });
                document.getElementById("idFileEntrante").value = null;

            }
        } catch (e) {

        }
    };

    $('#file-required').on("change", payment_file_size);
    $('#file-invoice-required').on("change", tax_situation_file_size);
    $('#idFileSaliente').on("change", fileSaliente);
    $('#idFileIne').on("change", fileIne);
    $('#idFileOficio').on("change", fileOficio);
    $('#idFileRepresentante').on("change", fileRepresentante);
    $('#idFileParticipantes').on("change", fileParticipantes);
    $('#idFileTestigo').on("change", fileTestigo);
    $('#idFileEntrante').on("change", fileEntrante);


    $('#asignar_na').hide();
    $('#observacionesProceso').hide();



    if ($('#checkbox_proceso_finalizado').is(':checked')){
        $('#ActaFirmada').show()
    }else{
        $('#ActaFirmada').hide()
    }
    $('#checkbox_proceso_finalizado').on("change", function () {
        if ($('#checkbox_proceso_finalizado').is(':checked')){
            $('#ActaFirmada').show()
        }else{
            $('#ActaFirmada').hide()
        }
    })


    if ($('#tieneObservaciones').val() == 'true'){
        $('#observacionesProceso').show();
    }else{
        $('#observacionesProceso').hide();
    }
    $('#tieneObservaciones').on("change", function () {
        if ($('#tieneObservaciones').val() == 'true'){
            $('#observacionesProceso').show();
        }else{
            $('#observacionesProceso').hide();
        }
    })

    if ($("#primertd").length > 0) {
        $("#aplica option[value='0']").remove()
    } else {
        $("#aplica").add(new Option('N/A', '0'))
    }
    // Custom search field for table content
    $('.search-field').on('change', function () {
        let $this = $(this);
        $this.toggleClass('expand-input', $this.val() ? true : false);
    });

    $('[data-toggle="tooltip"]').tooltip();

    $('.select-process').click(function (event) {
        let $this = $(this);
        let process_id = $(this).data('process_id');
        let authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;

        $.ajax({
            url: `/api/v1/employees/update_user`,
            data: {
                user: {current_process_id: process_id},
                authenticity_token: authenticityToken
            },
            type: 'PUT',
            dataType: 'json',
            success: function (data) {
                $('.m-menu__item').tooltip('disable');
                $this.parent().tooltip('enable');
                toastr.success(I18n.t('messages.process_selected'));
                $('.title-select-process').html($this.children('span').html());
                location.reload();
            },
            error: function (error) {
                console.log(error);
                toastr.warning(I18n.t('messages.try_again'));
            }
        });
    });

    // Sweet Alert modal for delete elements
    $('.btn-destroy').click(function (event) {
        let element, resource, resourceId, resourceMsg, row, url, authenticityToken;
        element = $(this);
        resource = element.data('resource');
        resourceId = element.data('resource-id');
        resourceMsg = element.data('resource-message');
        url = `/${resource}/${resourceId}`;
        authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        swal(resourceMsg, {
            icon: 'warning',
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: I18n.t('messages.delete'),
                    closeModal: true
                }
            }
        }).then(function (isConfirm) {
            if (!isConfirm) {
                return;
            }
            $('#MyModal').modal('show')
            $.ajax({
                url: url,
                data: {authenticity_token: authenticityToken},
                type: 'DELETE',
                dataType: 'json',
                success: function (data) {
                    $('#MyModal').modal('hide')
                    swal({
                        title: I18n.t('messages.done'),
                        text: I18n.t('messages.deleted'),
                        icon: 'success',
                        button: I18n.t('messages.ok')
                    }).then(function () {
                        location.reload()
                    });
                },
                error: function (error) {
                    let message = I18n.t('messages.try_again');
                    if (error.responseJSON && error.responseJSON.message) {
                        message = error.responseJSON.message;
                    }
                    swal(I18n.t('messages.error'), message, 'error');
                }
            });
        });
    });

    $('#complete').click(function (event) {
        if ($('#no_data').is(':visible')) {
            swal({
                title: "Formulario incompleto",
                text: "No hay registros",
                icon: 'warning',
                button: I18n.t('messages.ok')
            });
        } else {
            let element, resource, resourceId, resourceMsg, row, url, authenticityToken;
            element = $(this);
            resource = element.data('resource');
            resourceId = element.data('resource-id');
            resourceMsg = element.data('resource-message');
            url = `/${resource}/${resourceId}`;
            authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
            swal(resourceMsg, {
                icon: 'warning',
                buttons: {
                    cancel: I18n.t('messages.cancel'),
                    confirm: {
                        text: "Finalizar",
                        closeModal: true
                    },
                }
            }).then(function (isConfirm) {
                if (isConfirm == null) {
                    return;
                }
                $.ajax({
                    url: url,
                    data: {
                        authenticity_token: authenticityToken,
                        aplica: $('#aplica').val(),
                        proceso_entrega_recepcion_id: $('#proceso_entrega_recepcion_id').val()
                    },
                    type: 'GET',
                    dataType: 'json',
                    success: function (data) {
                        swal({
                            title: I18n.t('messages.done'),
                            text: "Finalizado",
                            icon: 'success',
                            button: I18n.t('messages.ok')
                        }).then(function () {
                                $.ajax({
                                    url: `/reporte/${resourceId}/${false}/reporte`,
                                    data: {
                                        authenticity_token: authenticityToken
                                    },
                                    type: 'GET',
                                    dataType: 'json',
                                    success: function (data) {
                                        window.location.href = '/entrega_recepcion_anexos/' + $('#proceso_entrega_recepcion_id').val()
                                    },
                                    error: function (error) {
                                        let message = I18n.t('messages.try_again');
                                        if (error.responseJSON && error.responseJSON.message) {
                                            message = error.responseJSON.message;
                                        }
                                        console.log(error)
                                        swal(I18n.t('messages.error'), message, 'error');
                                    }
                                });
                        });
                    },
                    error: function (error) {
                        let message = I18n.t('messages.try_again');
                        if (error.responseJSON && error.responseJSON.message) {
                            message = error.responseJSON.message;
                        }
                        swal("Error", message, 'error');
                    }
                });
            }, function (e) {
                console.log(e)
            });
        }
    });


    $('#save_item').click(function (event) {
        let element, resource, resourceId, resourceTable, resourceMsg, row, url, authenticityToken;
        element = $(this);
        resource = element.data('resource');
        resourceId = element.data('resource-id');
        resourceTable = element.data('resource-table');
        resourceMsg = element.data('resource-message');
        url = `/${resource}/${resourceId}`;
        authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        swal(resourceMsg, {
            icon: 'warning',
            buttons: {
                cancel: I18n.t('messages.cancel'),
                confirm: {
                    text: "Guardar",
                    closeModal: true
                },
            }
        }).then(function (isConfirm) {
            if (isConfirm == null) {
                return;
            }
            $('#MyModal').modal('show')
            var formData = new FormData();
            formData.append('authenticity_token', authenticityToken);
            formData.append('table', element.data('resourceTable'));
            formData.append('aplica', $('#aplica').val());
            formData.append('observaciones', $('#observaciones').val());

            var file = document.getElementById("excel_file");

            if (file) {
                formData.append('excel_file', file.files[0]);
            }
            $.ajax({
                url: url,
                type: 'PUT',
                data: formData,
                contentType: false,
                enctype: 'multipart/form-data',
                processData: false,
                success: function (data) {
                    $('#MyModal').modal('hide')
                    swal({
                        title: I18n.t('messages.done'),
                        text: "Guardado",
                        icon: 'success',
                        button: I18n.t('messages.ok')
                    }).then(function () {
                        //window.location.href='/entrega_recepcion_anexos/' + $('#proceso_entrega_recepcion_id').val()
                        window.location.reload();
                    });
                },
                error: function (error) {
                    console.log(error)
                    let message = "No puedes realizar esta acción";
                    if (error.responseJSON && error.responseJSON.message) {
                        message = error.responseJSON.message;
                    }
                    swal("Error", message, 'error');
                }
            });
        }, function (e) {
            console.log(e)
        });
    });

    // Sweet Alert modal for delete elements
    $('#delete_image').click(function (event) {
        let element, resource, resourceId, resourceData, url, authenticityToken;
        element = $(this);
        resource = element.data('resource');
        resourceId = element.data('resource-id');
        resourceData = element.data('resource-data')
        url = `/${resource}/${resourceId}/${resourceData}`;
        authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        $.ajax({
            url: url,
            data: {authenticity_token: authenticityToken},
            type: 'POST',
            dataType: 'json',
            success: function (data) {
                location.reload()
            },
            error: function (error) {
                let message = I18n.t('messages.try_again');
                if (error.responseJSON && error.responseJSON.message) {
                    message = error.responseJSON.message;
                }
                swal(I18n.t('messages.error'), message, 'error');
            }
        });
    });

    $('#delete_image_controller').click(function (event) {
        let element, resource, resourceId, resourceData, url, authenticityToken;
        element = $(this);
        resource = element.data('resource');
        resourceId = element.data('resource-id');
        resourceData = element.data('resource-data')
        url = `/remove_image_controller/${resource}/${resourceId}/${resourceData}`;
        authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        $.ajax({
            url: url,
            data: {authenticity_token: authenticityToken},
            type: 'POST',
            dataType: 'json',
            success: function (data) {
                location.reload()
            },
            error: function (error) {
                let message = I18n.t('messages.try_again');
                if (error.responseJSON && error.responseJSON.message) {
                    message = error.responseJSON.message;
                }
                swal(I18n.t('messages.error'), message, 'error');
            }
        });
    });

    $('#delete_file').click(function (event) {
        let element, resource, resourceId, resourceData, url, url_new, authenticityToken;
        element = $(this);
        resource = element.data('resource');
        resourceId = element.data('resource-id');
        resourceData = element.data('resource-data');
        console.log(resourceData)
        url = `/${resource}/${resourceId}`;
        url_new = `/${resource}/new?entrega_recepcion_anexo_id=${resourceData}`
        authenticityToken = $('[name="csrf-token"]')[0] && $('[name="csrf-token"]')[0].content;
        $.ajax({
            url: url,
            data: {authenticity_token: authenticityToken},
            type: 'DELETE',
            dataType: 'json',
            success: function (data) {
                // location.reload()
                window.location.href = url_new
            },
            error: function (error) {
                let message = I18n.t('messages.try_again');
                if (error.responseJSON && error.responseJSON.message) {
                    message = error.responseJSON.message;
                }
                swal(I18n.t('messages.error'), message, 'error');
            }
        });
    });

    $('#aplica').on('change', function () {
        let value = $('#aplica :selected').val();
        if (value == 0) {
            $('#captura_de_datos').hide()
            $('#new_record').hide()
            $('#asignar_na').show()
            $('#btn_submit').hide()
        } else {
            $('#captura_de_datos').show()
            $('#new_record').show()
            $('#btn_submit').show()
            $('#asignar_na').hide()
        }
    })

    $('#aplica_archivos').on('change', function () {
        let value = $('#aplica_archivos :selected').val();
        if (value == 0) {
            $('#captura_de_datos').hide()
            $('#no_aplica_archivos').val(1)
        } else {
            $('#captura_de_datos').show()
            $('#no_aplica_archivos').val(0)
        }
    })

    if ($('#aplica_archivos :selected').val() != undefined) {
        if ($('#aplica_archivos :selected').val() == 0) {
            $('#captura_de_datos').hide()
            $('#no_aplica_archivos').val(1)
        } else {
            $('#captura_de_datos').show()
            $('#no_aplica_archivos').val(0)
        }
    }

    if ($('#aplica :selected').val() != undefined) {
        if ($('#aplica :selected').val() == 0) {
            $('#captura_de_datos').hide()
            $('#no_aplica').val(1)
        } else {
            $('#captura_de_datos').show()
            $('#no_aplica').val(0)
        }
    }

    // bootstrap-select initializer
    $('.selectpicker, .per-page-selectpicker').selectpicker();

    // select2 initializer
    $('.select2-multiple').select2({
        closeOnSelect: false,
    });

    // Submit for elements per page on tables
    $('.per-page-pagination').on('change', function () {
        $(this).closest('form').submit();
    });

    // Simulate click for file_field on edit user
    $('.hover-image').on('click', function () {
        $('.update-picture').click();
    });

    // Replace profile picture on edit user
    $('.update-picture').on('change', function () {
        readURL(this);
    });

    $('#user_unit_id').on('change', function () {
        $.ajax({
            url: `/api/v1/employees/units_pres`,
            data: {unit_id: $(this).val()},
            type: 'GET',
            dataType: 'json',
            success: function (data) {
                $('#user_unit_pres_id').empty();
                for (let action of data) {
                    $('#user_unit_pres_id').append($('<option>', {
                        value: action.Id_UnidadPres,
                        text: action.Descripcion.trim()
                    }));
                }
                $('.selectpicker').selectpicker('refresh');
            },
            error: function (error) {
                toastr.warning(I18n.t('messages.try_again'));
            }
        });
    });

    $('#activity_type').on('change', function () {
        console.log('change');
;        var selectedValue = $(this).val();
        console.log(selectedValue);
        if (selectedValue === 'evento_academico') {
            $('.activity-file').show();
            $('.form-type').show();
        } else {
            $('.activity-file').hide();
            $('.form-type').hide();
        }
    });
    $('#activity_type').trigger('change');
});

let getReviewers = (revId, rev2Id) => {
    $.ajax({
        type: 'GET',
        url: '/get_avaliable_reviewers',
        contentType: "application/json",
        dataType: 'json',
        data: {user_event_id: $("#user_event_id").val()},
        success: function (result) {
            if (result) {

                var array = [];
                var array2 = [];
                array.push('<option value="0">SELECCIONA UN REVISOR</option>');
                array2.push('<option value="0">SELECCIONA UN REVISOR</option>');

                $.each(result['reviewers'], function (i, val) {
                    if(revId === val["id"]){
                        array.push('<option value="' + val["id"] + '" selected>' + val["user_name"] + '</option>');
                    }else{
                        if(rev2Id != val["id"]){
                            array.push('<option value="' + val["id"] + '">' + val["user_name"] + '</option>');
                        }
                    }
                    if(rev2Id === val["id"]){
                        array2.push('<option value="' + val["id"] + '" selected>' + val["user_name"] + '</option>');
                    }else{
                        if(revId != val["id"]){
                            array2.push('<option value="' + val["id"] + '">' + val["user_name"] + '</option>');
                        }
                    }
                });

                $('#reviewers_select').empty().append(array.join(''));
                $('#reviewers_select_2').empty().append(array2.join(''));
                $('#reviewers_select').val(revId);
                $('#reviewers_select_2').val(rev2Id);

                if(result['rev_1_exist']){
                    $('#reviewers_select').prop('disabled', true);
                    $('#reviewers_select').select2({ disabled: true });
                }
                if(result['rev_2_exist']){
                    $('#reviewers_select_2').prop('disabled', true);
                    $('#reviewers_select_2').select2({ disabled: true });
                }
            }
        },
        error: function (error) {
            $("#reviewers_select").empty();
            $("#reviewers_select_2").empty();
            $("#reviewers_select").append($("<option></option>").attr("value", '').text('Selecciona un revisor'));
            $("#reviewers_select_2").append($("<option></option>").attr("value", '').text('Selecciona un revisor'));
        }
    });
};

let readURL = (input) => {
    if (input.files[0]) {
        var reader = new FileReader();
        let ext = getFileExtension(input.files[0].name);
        let validImageTypes = ['gif', 'jpeg', 'png', 'jpg'];
        if (validImageTypes.includes(ext)) {
            reader.readAsDataURL(input.files[0]);
        }
    }

    reader.onload = function (e) {
        $('.profile-picture').attr('src', e.target.result);
        $('.profile-picture').attr('style', 'object-fit:cover; width:130px; height:130px;');
    }
};

let getFileExtension = (filename) => {
    return filename.split('.').pop();
};

let cascadeSelects = (source, destination, url) => {
    let ajaxRequest = (resource, edit) => {
        if (resource !== '') {
            $.get(url, {resource: resource}, function (data) {
                destination.empty();
                if (data.length > 0) {
                    for (let action of data) {
                        destination.append($('<option>', {value: action[0], text: action[1]}));
                    }
                    edit && destination.val(destination.data('value'));
                } else {
                    destination.append($('<option>', {value: '', text: I18n.t('helpers.select.no_options')}));
                }
                $('.selectpicker').selectpicker('refresh');
            })
                .fail(function () {
                    toastr.warning(I18n.t('messages.try_again'));
                });
        }
    };

    ajaxRequest(source.val(), true);

    source.on('change', function (e) {
        let resource = $(e.target).val();
        ajaxRequest(resource, false);
    });
};

export {cascadeSelects};
